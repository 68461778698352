import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25ModalFooterComponent } from "./s25.modal.footer.component";

@TypeManagerDecorator("s25-ng-modal")
@Component({
    selector: "s25-ng-modal",
    template: `
        @if (isOpen) {
            <div #s25Modal class="s25-modal" (click)="onOutsideClick($event)" (keydown.escape)="close()" tabindex="-1">
                <div class="s25-modal--content {{ size }}">
                    <div class="s25-modal--header">
                        <div class="title">
                            <ng-container [ngTemplateOutlet]="header"></ng-container>
                            @if (!header && title) {
                                <h4 class="title">{{ title }}</h4>
                            }
                        </div>
                        <button #closeButton class="s25-modal--close" (click)="close()">
                            <s25-ng-icon [type]="'close'"></s25-ng-icon>
                        </button>
                    </div>
                    <div class="s25-modal--body">
                        <ng-container [ngTemplateOutlet]="body"></ng-container>
                        @if (!body) {
                            <ng-content></ng-content>
                        }
                    </div>
                    @if (footer || type !== "custom") {
                        <s25-ng-modal-footer
                            #footerComponent
                            [content]="footer"
                            [type]="type"
                            (save)="onSave()"
                            (cancel)="close()"
                            (affirm)="onAffirm($event)"
                        ></s25-ng-modal-footer>
                    }
                </div>
            </div>
        }
    `,
    styles: `
        .s25-modal {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: opacity 100ms ease-out;
            z-index: 1059; /* one less than popover for multiselects*/
        }

        .s25-modal--content {
            background: white;
            border-radius: 3px;
            box-shadow:
                0 15px 35px 0 rgb(50 50 93 / 10%),
                0 5px 15px 0 rgb(0 0 0 / 7%);
            width: 75vw;
            max-height: 95vh;
            margin: 2.5vh auto;
            display: flex;
            flex-direction: column;
            transition: transform 300ms ease-out;
            transform: translateY(-50px);
        }

        .s25-modal.open .s25-modal--content {
            transform: none;
        }

        .s25-modal--content.xl {
            max-width: 1200px;
        }

        .s25-modal--content.lg {
            max-width: 1000px;
        }

        .s25-modal--content.md {
            max-width: 800px;
        }

        .s25-modal--content.sm {
            max-width: 600px;
        }

        .s25-modal--content.xs {
            max-width: 500px;
        }

        .s25-modal--content.xxs {
            max-width: 400px;
        }

        .s25-modal--header {
            display: flex;
            justify-content: space-between;
            padding: 0.5em 1em;
            /*border-bottom: 1px solid;*/
        }

        .s25-modal--header .title {
            font-weight: bold;
            margin: auto 0;
            flex: 1;
        }

        .s25-modal--close {
            border: 0;
            background: transparent;
            color: #6c6c6c;
            padding: 0;
            cursor: pointer;
            border-radius: 50%;
            height: 2em;
            width: 2em;
            transition: all 0.2s;
        }

        .s25-modal--close:hover {
            background: #fafaf9;
            color: var(--color-primary) !important;
        }

        .s25-modal--close:focus {
            box-shadow: 0 0 0 2px var(--color-primary);
            color: var(--color-primary) !important;
            outline: 0;
        }

        .s25-modal--close:active {
            background: #f0f1f2;
        }

        .s25-modal--body {
            overflow-y: auto;
            padding: 0.5em 1em;
        }

        .pulsing {
            transform: scale(1.02) !important;
        }

        .open {
            opacity: 1;
        }

        ::ng-deep #s25.nm-party--on s25-ng-modal .s25-modal--content {
            background-color: #3c3d46;
        }

        ::ng-deep #s25.nm-party--on s25-ng-modal .s25-modal--close {
            color: #fff;
        }

        ::ng-deep #s25.nm-party--on s25-ng-modal .s25-modal--close:hover {
            background: #4a4851;
            color: #73b0e2 !important;
        }

        ::ng-deep #s25.nm-party--on s25-ng-modal .s25-modal--close:focus {
            box-shadow: 0 0 0 2px #73b0e2;
            color: #73b0e2 !important;
            background: #4a4851;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ModalComponent implements S25Modal<unknown> {
    @Input() title: string;
    @Input() type: S25ModalFooterComponent["type"] = "custom";
    @Input() size: "xxs" | "xs" | "sm" | "md" | "lg" | "xl" = "md";

    @Output() save = new EventEmitter<void>();
    @Output() modalClosed = new EventEmitter<void>();

    @ContentChild("s25ModalHeader") header: TemplateRef<any>;
    @ContentChild("s25ModalBody") body: TemplateRef<any>;
    @ContentChild("s25ModalFooter") footer: TemplateRef<any>;
    @ViewChild("closeButton") closeButton: any;
    @ViewChild("s25Modal") modal: ElementRef;
    @ViewChild("footerComponent") footerComponent: S25ModalFooterComponent;

    isOpen: boolean = false;
    returnFocusTo: HTMLElement;
    resolveClosePromise: (data: any) => void;

    constructor(public changeDetector: ChangeDetectorRef) {}

    open() {
        this.isOpen = true;
        const closePromise = new Promise((resolve) => {
            this.resolveClosePromise = resolve;
        });
        this.changeDetector.detectChanges();
        this.returnFocusTo = document.activeElement as HTMLElement;
        this.closeButton.nativeElement.focus();
        // Not sure why the timeout is needed here for the transition animation to show, but it is
        setTimeout(() => {
            this.modal.nativeElement.classList.add("open");
        });
        return closePromise;
    }

    async close(data?: any) {
        this.modal.nativeElement.classList.remove("open");
        await S25Util.delay(100);
        this.isOpen = false;
        this.changeDetector.detectChanges();
        this.resolveClosePromise(data);
        this.resolveClosePromise = null;
        this.returnFocusTo.focus();
        this.modalClosed.emit();
    }

    stopLoading() {
        this.footerComponent?.stopLoading();
    }

    onSave() {
        this.save.emit();
    }

    onAffirm(yes: boolean) {
        this.close(yes);
    }

    onOutsideClick(event: MouseEvent) {
        const target = event.target as Element;
        if (target !== this.modal.nativeElement) return;
        return this.pulse(target.firstChild as Element);
    }

    async pulse(element: Element) {
        element.classList.add("pulsing");
        await S25Util.delay(200);
        element.classList.remove("pulsing");
    }
}

export interface S25Modal<T = void> {
    open(): Promise<T>;
    close(): T;
}
