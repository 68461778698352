import { NgModule } from "@angular/core";
import { S25ModalComponent } from "./s25.modal.component";
import { CommonModule } from "@angular/common";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25ModalFooterComponent } from "./s25.modal.footer.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";

@NgModule({
    imports: [CommonModule, S25IconModule, S25LoadingInlineModule],
    exports: [S25ModalComponent, S25ModalFooterComponent],
    declarations: [S25ModalComponent, S25ModalFooterComponent],
    providers: [S25ModalComponent, S25ModalFooterComponent],
})
export class S25ModalModule {}
